import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const BSWUrologyLandingPage = () => (
  <PhysicianLandingPage
    physician="BSW Hillcrest Urology"
    institution="BSW Hillcrest Urology"
    referralCode="BSWURO"
    physicianURL="https://www.bswhealth.com/locations/waco-hillcrest-urology"
  />
)

export default BSWUrologyLandingPage
